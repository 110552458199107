@import "../../../src/scss/themes/basic/abstracts/custom_variables";
@import "_default/block_hero.scss";

.block_hero {
    &.has_overlay:after {
        background: $color-tertiary;
        mix-blend-mode: overlay;
        opacity: 0.5;
        z-index: 3;
    }

    &.has_overlay:before {
        background: $color-primary;
        mix-blend-mode: overlay;
        z-index: 2;
    }
}
