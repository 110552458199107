@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero {
    min-height: 80vh;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    margin-top: -110px;

    @include breakpoint(xlarge) {
        min-height: 100vh;
        margin-top: -164px;
    }

    &.has_overlay:after,
    &.has_overlay:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
    }

    .grid-container {
        z-index: 4;
        padding-top: 200px;
        padding-bottom: 32px;

        @include breakpoint(xlarge) {
            padding-bottom: 72px;
        }
    }

    .media {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;

        picture {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }
        }
    }

    .content {
        margin-bottom: 18px;

        @include breakpoint(xlarge) {
            margin-bottom: 42px;
        }

        @include breakpoint(xlarge) {
            .animate-text {
                max-width: 45%;
            }
        }

        .heading-h3 {
            margin-bottom: 16px;

            @include breakpoint(xlarge) {
                margin-bottom: 24px;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: center;

        @include breakpoint(small only) {
            flex-direction: column;
        }

        .button {
            margin: 6px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1973";